.App {
    text-align: center;
    /*min-height: 100%;*/
    min-height: 100vh;
    /*!*position: fixed;*!*/
    /*bottom: 0;*/
    /*max-width: 100vw;*/
    display: flex;
    /*overflow-y: auto;*/
    background-size: auto;

    /* background: "#041d29"; */
}

body {
    /* background: #121212; */
    /* background: transparent; */
    /* background: "#041d29"; */
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}














textarea {
    background-color: #121212;
    color: white;
}

.graphiql-container .search-box>input {
    background-color: #121212;
    color: white;
}

.graphiql-container .doc-type-description p:first-child,
.graphiql-container .doc-type-description blockquote:first-child {
    color: white
}


body::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.powered-by {
    display: none;
    background: transparent;
}

graphql-voyager {
    max-height: 400px;
    max-width: 100vw;
}

html {
    scroll-behavior: smooth;
    /* background-color: #121212; */
    background-color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 1s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.svg_icons {
    transform: scale(1.8);
}

.MuiAutocomplete-listbox {
    background: #1595CE;
    color: white;
}

.portal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.portal-overlay .confirm-dialog {
    z-index: 1000000000000111;
    padding: 16px;
    /*background-color: white;*/
    /*max-width: 400px;*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*border: 1px solid rgba(0, 0, 0, 0.2);*/
    border-radius: 5px;
}

.portal-overlay .confirm-dialog__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}